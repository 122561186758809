import React from "react"
import TextField from "@material-ui/core/TextField"
import Button from "./btn"
import { NameWrapper, Wrapper } from "../styles"

export default () => (
  <form
    name="Contact Form"
    method="POST"
    action="https://formsubmit.co/ida215@nyu.edu"
  >
    <NameWrapper>
      <TextField name="name" variant="outlined" id="Name" label="Name" />
    </NameWrapper>
    <NameWrapper>
      <TextField
        name="email"
        type="email"
        variant="outlined"
        id="Email"
        label="Email"
      />
    </NameWrapper>
    <NameWrapper>
      <TextField
        name="message"
        type="textarea"
        variant="outlined"
        id="Message"
        label="Message"
        multiline
        rows={6}
      />
    </NameWrapper>
    <input
      type="hidden"
      name="_subject"
      value="New Contact From Website"
    ></input>
    <input type="hidden" name="_captcha" value="false"></input>
    <input
      type="hidden"
      name="_next"
      value="https://ioannisangelakis.com/contact/#sent"
    ></input>
    <Wrapper alignEnd>
      <Button type="submit">Submit</Button>
    </Wrapper>
  </form>
)
