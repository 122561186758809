import React, { useEffect } from "react"

import Layout from "../components/layout"
import ContactForm from "../components/form"
import SEO from "../components/seo"
import Link from "../components/link"
import {
  Display,
  Wrapper,
  LogoYoutube,
  LogoFacebook,
  LogoInstagram,
  LogoSoundcloud,
  LogoVimeo,
  ContactSuccess,
} from "../styles"
import { navigate } from "gatsby"

export default ({ location }) => {
  useEffect(() => {
    if (location.hash === "#sent") {
      setTimeout(() => navigate("/contact/"), 2500)
    }
  }, [location])

  return (
    <Layout>
      <SEO title="Contact" />
      <Display center>Contact</Display>

      {location.hash === "#sent" && (
        <ContactSuccess alignCenter justifyCenter>
          Thanks for the message, I will get back to you shortly!
        </ContactSuccess>
      )}
      <ContactForm />

      <Wrapper row wrap alignCenter justifyCenter>
        <Link to="https://soundcloud.com/johnange">
          <LogoSoundcloud />
        </Link>
        <Link to="https://vimeo.com/user65622011/videos">
          <LogoVimeo />
        </Link>
        <Link to="https://www.youtube.com/channel/UCiGV1u40xdHFrdLgchbm_DA">
          <LogoYoutube />
        </Link>
        <Link to="https://www.facebook.com/ioannis.angelakis.7">
          <LogoFacebook />
        </Link>
        <Link to="https://www.instagram.com/ioannis_angelakis/">
          <LogoInstagram />
        </Link>
      </Wrapper>
    </Layout>
  )
}
